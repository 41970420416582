<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Professionālās meistarības pilnveide"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <div class="float-right d-flex flex-column" style="gap: 10px;">
            <b-button variant="success" :to="{ name: 'CourseCreate' }" v-can="'documents.create'">Pievienot</b-button>
            <b-button variant="warning" :to="{ name: 'CourseList' }">Notīrīt filtru</b-button>
          </div>

          <b-col cols="12" class="text-center" v-if="!loaded">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Professionālās pilnveides kurss tika veiksmīgi arhivēts!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-row>
            <b-col lg="9" class="my-1">
              <b-form-group
                  class="m-1"
              >
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="searchInput"
                      :debounce="300"
                      type="search"
                      placeholder="Meklēt pēc vārda, uzvārda"
                  ></b-form-input>

                </b-input-group>

              </b-form-group>

              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="selectDateOfOrderFilter"
                    :debounce="300"
                    type="date"
                    placeholder="Meklēt pēc rīkojuma dautuma"
                ></b-form-input>
                <b-form-input
                    id="filter-input"
                    v-model="selectedCountOfHoursFilter"
                    :debounce="300"
                    type="search"
                    placeholder="Stundu skaits"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>

          <b-table striped hover responsive="true" small v-b-hover fixed
                   ref="table"
                   :items="items"
                   :fields="fields"
                   :no-provider-sorting="true"
                   @sort-changed="sortingChanged"
                   v-if="loaded">

            <template #cell(actions)="row">
              <b-button size="sm" variant="warning" @click="$router.push({ name: 'CourseView', params: {id: row.item.id} })" class="mr-1 mt-2">
                {{ hasPermission('documents.update') ? 'Skatīt/labot' : 'Skatīt' }}
              </b-button>
              <b-button v-if="hasPermission('documents.destroy')" size="sm" variant="danger" class="mt-2" v-b-modal.modal-sm @click="archiveItem(row.item.id, row.index)">
                Arhivēt
              </b-button>
            </template>

            <template #cell(order_date)="row">
              <span> {{ dateFormat(row.item.order_date, "dd.mm.yyyy") }} </span>
            </template>

            <template #cell(start_date)="row">
              <span v-if="row.item.start_date">{{ dateFormat(row.item.start_date, "dd.mm.yyyy") }} </span>
              <span v-else>Nav</span>
            </template>

            <template slot="bottom-row">
              <td>Kopā</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ totalHours }}</td>
            </template>
          </b-table>
          <template v-if="isBusy && !noData">
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Dati tiek lejupielādēti...</strong>
            </div>
          </template>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";

const dateFormat = require('dateformat');

export default {
  name: "List",
  data() {
    return {
      dateFormat: dateFormat,
      courses: null,
      created: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      currentPage: 1,
      noData: false,
      search: null,
      selectDateOfOrder: null,
      selectedCountOfHours: null,
      sort: null,
      isBusy: false,
      items: [],
      selectType: 0,
      selectedTheme: null,
      numberOfHours: null,
      searchString: '',
      fields: [
        { key: 'order_number', label: 'Rīkojuma numurs', sortable: true},
        { key: 'name', label: 'Vārds' },
        { key: 'surname', label: 'Uzvārds'},
        { key: 'order_date', label: 'Rīkojuma datums', sortable: true},
        { key: 'hours', label: 'Stundu skaits', sortable: true},
        { key: 'start_date', label: 'Trīs gadu termiņa sākuma datums', sortable: true},
        { key: 'actions', label: 'Darbības' }
      ],
      loaded: true,
    }
  },
  mounted() {
    let name = this.$route.params.name
    if(name) {
      let date = new Date();
      date.setFullYear(date.getFullYear()-3);

      this.datePicker.dateStart = date.toISOString();
      this.search = name;
      this.onFilter();
    } else {
      this.getData();
    }

    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight <= 0;
      if (bottomOfWindow && !this.isBusy) {
        this.getData();
      }
    };
  },
  computed: {
    ...mapGetters ({
      User: "Auth/user",
    }),
    totalHours () {
      return this.items.reduce((accum, item) => {
        return accum + parseFloat(item.hours)
      }, 0)
    },
    searchInput: {
      get() {
        return this.search;
      },
      set(value) {
        this.search = value;
        this.onFilter();
      }
    },
    selectDateOfOrderFilter: {
      get() {
        return this.selectDateOfOrder;
      },
      set(value) {
        this.selectDateOfOrder = value;
        this.onFilter();
      }
    },
    selectedCountOfHoursFilter: {
      get() {
        return this.selectedCountOfHours;
      },
      set(value) {
        this.selectedCountOfHours = value;
        this.onFilter();
      }
    }

  },
  methods: {
    archiveItem(id, index) {
      axios.delete('/courses/'+id).then(response => {

        this.items.splice(index, 1);

        this.created = 'success';
      }).catch(e => {
        this.created = 'error';
      })

      this.dismissCountDown = 5;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    async getData() {
      this.isBusy = true;
      await axios.get('/courses?page[number]=' +
          this.currentPage +
          (this.sort ? '&sort=' + (this.sort.sortDesc ? '-' : '')+this.sort.field : '') +
          this.searchString
      ).then(response => {

        let data = response.data.data;

        if(data.length) {
          this.items = this.items.concat(data);
          this.noData = false;
          this.isBusy = false;
        } else {
          this.noData = true;
        }
      }).catch(e => {
        this.isBusy = false;
      })

      this.currentPage++;
    },
    sortingChanged(ctx) {
      switch(ctx.sortBy) {
        case 'issue_date':
          ctx.sortBy = 'issueDate';
          break;
        case 'valid_date':
          ctx.sortBy = 'validDate';
          break;
      }

      this.sort = {
        'field': ctx.sortBy,
        'sortDesc': ctx.sortDesc,
      }

      this.currentPage = 1;
      this.items = [];
      this.getData();
    },
    onFilter() {
      this.currentPage = 1;
      this.items = [];

      this.searchString = "";
      // let dateType = "";

      // switch(this.selectType) {
      //   case 0:
      //     dateType = 'issueDate';
      //     break;
      //
      //   case 1:
      //     dateType = 'validDate';
      //     break;
      // }


      if(this.search) {
        this.searchString += '&filter[name]=' + this.search;
      }

      if(this.selectDateOfOrder) {
        this.searchString += '&filter[order_date]=' + this.selectDateOfOrder;
      }

      if(this.selectedCountOfHours) {
        this.searchString += '&filter[hours]=' + this.selectedCountOfHours;
      }
      // Object.keys(this.datePicker).forEach(e => {
      //   if(this.datePicker[e]) {
      //     if(e === 'dateEnd' && this.datePicker['dateStart']) {
      //       this.searchString += ','
      //     }
      //     this.searchString += this.datePicker[e]
      //   }
      // })

      this.getData();
    },
  },
}
</script>

<style scoped>

</style>